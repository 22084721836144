/*
 * @Author: qian tang
 * @Date: 2022-10-12 15:17:32
 * @LastEditTime: 2022-12-22 15:04:32
 * @LastEditors: Qian Tang qian@itrazotracetech.com
 * @Description:
 * @FilePath: \itrazo-portal-develop\src\containers\Pages\ProductTracking\Alerts\index.tsx
 * All rights reserved
 */

import React, { useState, useEffect, ChangeEvent } from "react";
import axios from "axios";

import SearchButton from "../../../../components/SearchButton";
import {
  GridFive,
  StyledSelect,
  GridSix,
  ChainPageContainer,
  StyledInput,
  StyledTextInput,
  DateTimeContainer,
  GridSeven,
} from "../../../../statics/styles/StyledComponents";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import Statistics from "../../../../components/Statistics";
import diversion from "../../../../statics/images/diversion.svg";
import boundary from "../../../../statics/images/boundaryicon.svg";
import temp from "../../../../statics/images/temp.svg";
import counter from "../../../../statics/images/counter.svg";
import valueAlert from "../../../../statics/images/valueAlert.svg";
import failureAlert from "../../../../statics/images/failureAlert.svg";
import { LevelItem } from "../../../../utilities/types";
import { trackingLevel } from "../../../../statics/staticDataset";
import { VALUE_CHAIN_API } from "../../../../utilities/AdiApi";
import {
  ALERTS_TYPES,
  ALERT_BACKGROUNDS_SETS,
  DATE_FORMAT_LIST,
  DATE_TIME_FORMAT,
  TABLE_CELL_CONFIG,
} from "../../../../utilities/CONSTANTS";
import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import {
  getAllIDsPromise,
  getBizCode,
  getRefIDsPromise,
} from "../../../../utilities/Functions/GlobalHelperFunctions";
import Loading from "../../../../components/Loading";
import STATIC_IMAGES from "../../../../statics/images";
import AdiTable from "../../../../components/AdiTable";
import { formatTitle, getLocationFromEvent } from "../../../../utilities/Functions/FormatFunctions";
import Input from "../../../../components/Input";
import { getSelectorOptions } from "../../../../utilities/Functions/GlobalHelperFunctions";
import StatsHeader from "../components/Header/StatsHeader";
import DisplayStats from "components/Statistics/DisplayStats";
import { PlusOutlined } from "@ant-design/icons";
import FilterHeader from "../components/Header/FilterHeader";
import TableCard from "components/AdiTable/TableCard";

type Props = {
  url?: string;
};

// export const Input = styled.input`
//   outline: none;
//   border: none;
//   width: 100%;
//   text-overflow: ellipsis;
//   color: white;
//   background: rgb(75, 75, 75);
//   padding: 0.5rem;
//   border: 1px solid transparent;

//   &::placeholder {
//     color: rgb(126, 126, 126);
//   }

//   &:focus {
//     border-color: ${(props) => props.theme.main};
//   }
// `;

const Header = styled.div`
  * {
    color: white;
  }

  & > p {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  display: flex;
  align-item: flex-end;
  justify-content: flex-end;
`;

const Alerts = (props: Props) => {
  const BIZ_CODE = getBizCode();
  const [typeAlertTxt, setTypeAlertTxt] = useState("");
  const [searchAlertIssuesResuls, setSearchAlertIssuesResuls] = useState<any>(
    []
  );
  const [dataFailureVal, setDataFailureVal] = useState(1);
  const [tempAlertVal, setempAlertVal] = useState(0);
  const [multilocVal, setmultilocVal] = useState(0);
  const [iocVal, setiocVal] = useState(0);
  const [boundaryalertVal, setboundaryalertVal] = useState(0);
  const [diversionalertVal, setdiversionalertVal] = useState(0);
  const [alertCards, setAlertCards] = useState(
    ALERTS_TYPES.map((item: string, index: number) => ({
      bgOne: ALERT_BACKGROUNDS_SETS[item].bgOne,
      bgTwo: ALERT_BACKGROUNDS_SETS[item].bgTwo,
      icons: STATIC_IMAGES.ALERT_ICONS[item],
      title: item,
      value: 0,
    }))
  );
  // const [alertCards, setAlertCards] = useState([
  //   {
  //     bgOne: "#E2382E",
  //     bgTwo: "#EF7F48",
  //     icons: failureAlert,
  //     title: ALERTS_TYPES[0],
  //     value: 0,
  //   },
  //   {
  //     bgOne: "#EE931E",
  //     bgTwo: "#FBD224",
  //     icons: temp,
  //     title: ALERTS_TYPES[1],
  //     value: 0,
  //   },
  //   {
  //     bgOne: "#E2382E",
  //     bgTwo: "#EF7F48",
  //     icons: diversion,
  //     title: ALERTS_TYPES[2],
  //     value: 0,
  //   },
  // ]);
  // const alertCards = {
  //   [ALERTS_TYPES[0]]: {
  //     bgOne: "#E2382E",
  //     bgTwo: "#EF7F48",
  //     icons: failureAlert,
  //     title: "",
  //     value: 0,
  //   },
  //   [ALERTS_TYPES[1]]: {
  //     bgOne: "#EE931E",
  //     bgTwo: "#FBD224",
  //     icons: temp,
  //     title: "",
  //     value: 0,
  //   },
  //   [ALERTS_TYPES[2]]: {
  //     bgOne: "#E2382E",
  //     bgTwo: "#EF7F48",
  //     icons: diversion,
  //     title: "",
  //     value: 0,
  //   },

  // }
  const CardsData = [
    {
      bgOne: "#E2382E",
      bgTwo: "#EF7F48",
      icons: failureAlert,
      title: "Data Failure",
      value: dataFailureVal,
    },
    {
      bgOne: "#E2382E",
      bgTwo: "#EF7F48",
      icons: diversion,
      title: "Diversion Alert",
      value: diversionalertVal,
    },
    {
      bgOne: "#5b85c",
      bgTwo: "#489790",
      icons: counter,
      title: "IoT Comms Failure",
      value: iocVal,
    },
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: boundary,
      title: "Boundary Alert",
      value: boundaryalertVal,
    },
    {
      bgOne: "#EE931E",
      bgTwo: "#FBD224",
      icons: temp,
      title: "Temperature Alert",
      value: tempAlertVal,
    },
    {
      bgOne: "#9d5f10",
      bgTwo: "#e1d2907d",
      icons: valueAlert,
      title: "Multi-Location Alert",
      value: multilocVal,
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prodOrBatchIdList, setProdOrBatchIdList] = useState<any[]>([]);
  const [refIdList, setRefIdList] = useState<any[]>([]);
  const [custodyList, setCustodyList] = useState<any[]>([]);
  //   const [dataList, setDataList] = useState<any[]>([])
  const [filter, setFilter] = useState<any>({
    refID: "",
    chain: "",
    type: "",
    product_or_batch: "",
  });

  const initialize = () => {
    console.log("get the ref id list init");
    const url = `/valuechain/${BIZ_CODE}`;
    const promList = [];

    const refIdProm = getRefIDsPromise();
    promList.push(refIdProm);

    const allIDProm = getAllIDsPromise();
    promList.push(allIDProm);

    const allAlertProm = getAllAlertTypePromise();
    promList.push(allAlertProm);

    Promise.all(promList)
      .then((allPromiseRes) => {
        console.log("idsssss allPromiseRes", allPromiseRes);

        const refIDs: any = allPromiseRes[0];

        const allIDs: any = allPromiseRes[1];

        const allAlertRes: any = allPromiseRes[2];

        const updatedAlertsCard = ALERTS_TYPES.map((item, index) => {
          return {
            ...alertCards[index],
            value: allAlertRes[item].length,
            title: item,
          };
        });

        console.log("updatedAlertsCard", updatedAlertsCard);

        setAlertCards([...updatedAlertsCard]);

        // setProdOrBatchIdList(allIDList);
        setProdOrBatchIdList(allIDs);
        setRefIdList([...refIDs]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("get id all promise err", err);
        setIsLoading(false);
      });
  };
  // const initialize = () => {
  //   console.log("get the ref id list init");
  //   const url = `/valuechain/${BIZ_CODE}`;
  //   VALUE_CHAIN_API.get(url)
  //     .then((res: any) => {
  //       console.log("get the ref id list init Alerts res==>", res);

  //       if (res.status == "200") {
  //         const result = res.data.body.Items;
  //         console.log("get the ref id list init search ==>>", result);

  //         const promList = [];
  //         // const prodIdsList = getIDsPromise("products");
  //         // promList.push(prodIdsList);
  //         // const batchIdsList = getIDsPromise("batches");
  //         // promList.push(batchIdsList);
  //         const allIDProm = getAllIDsPromise()
  //         promList.push(allIDProm);

  //         // get alerts total number
  //         const allAlertProm = getAllAlertTypePromise()
  //         promList.push(allAlertProm);
  //         // ALERTS_TYPES.map((item) => {
  //         //   const alertProm = getAlertTypePromise(item);
  //         //   promList.push(alertProm);
  //         // });

  //         Promise.all(promList)
  //           .then((allPromiseRes) => {
  //             console.log("idsssss allPromiseRes", allPromiseRes);
  //             // const prodL: any = allPromiseRes[0];
  //             // const batchL: any = allPromiseRes[1];
  //             // console.log("idsssss", prodL, batchL);

  //             // const allIDList = [...prodL, ...batchL];
  //             // console.log("idsssss allIDList", allIDList);

  //             const allIDs:any = allPromiseRes[0]

  //             const allAlertRes:any = allPromiseRes[1]

  //             const updatedAlertsCard = ALERTS_TYPES.map((item, index) => {
  //               return {
  //                 ...alertCards[index],
  //                 value: allAlertRes[item].length,
  //                 title: item
  //               }
  //             })

  //             console.log('updatedAlertsCard', updatedAlertsCard)

  //             setAlertCards([...updatedAlertsCard])

  //             // setProdOrBatchIdList(allIDList);
  //             setProdOrBatchIdList(allIDs);
  //             setRefIdList(result);
  //           })
  //           .catch((err) => {
  //             console.log("get id all promise err", err);
  //           });

  //         //   setDataList(result)
  //       } else {
  //         console.log("error not 200 code");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("get chain err", err);
  //     });
  // };

  useEffect(() => {
    initialize();
  }, []);

  const columns: any = [
    {
      title: "Alert ID",
      dataIndex: "alert_ID",
      align: "center",
      key: "alert_ID",
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('id'),
    },
    {
      title: "Alert Type",
      dataIndex: "issue",
      align: "center",
      key: "issue",
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('first_name'),
    },
    {
      title: "Date",
      dataIndex: "datetime",
      align: "center",
      key: "datetime",
      render: (text: any, record: any) => (
        <>{moment(record.datetime).format(DATE_TIME_FORMAT)}</>
      ),
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('first_name'),
    },
    {
      title: "Ref ID",
      dataIndex: "ValueChain_refID",
      align: "center",
      key: "ValueChain_refID",
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('first_name'),
    },
    {
      title: "Business Step",
      dataIndex: ["event", "bizStep"],
      align: "center",
      //   key: 'issue',
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('first_name'),
    },
    {
      title: "Location",
      dataIndex: "event",
      align: "center",
      key: "issue",
      render: (text: any, record: any) => (
        <>{getLocationFromEvent(record.event)}</>
      ),
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('first_name'),
    },
    {
      title: "Custody",
      dataIndex: "chain",
      align: "center",
      //   key: 'issue',
      // fixed: 'left',
      // width: 100,
      //   ...getColumnSearchProps('first_name'),
    },
  ];

  const handleSearch = () => {
    console.log("filter", filter);
    const filterKeys = Object.keys(filter);

    let url = `alerts/${BIZ_CODE}`;

    let firstValue = true;
    filterKeys.forEach((key: string) => {
      if (
        filter[key].length > 0 &&
        key !== "start_date" &&
        key !== "end_date"
      ) {
        if (firstValue) {
          url = url + `?${key}=${filter[key]}`;
          firstValue = false;
        } else {
          url = url + `&${key}=${filter[key]}`;
        }
      }
    });

    if (filter.start_date || filter.end_date) {
      url =
        url +
        `${firstValue ? "?" : "&"}start_date=${filter.start_date
          ? filter.start_date
          : moment().subtract(10, "years").format("DD/MM/YYYY")
        }` +
        `&end_date=${filter.end_date ? filter.end_date : moment().format("DD/MM/YYYY")
        }`;
    }

    console.log("handleSearch alert url ===>>>>", url);

    setIsLoading(true);

    VALUE_CHAIN_API.get(url)
      .then((res: any) => {
        console.log("search alerts", res);

        const result = res.data.body;

        if (result.includes("No Alerts for refID")) {
          toast("No alerts found ! ");
          setSearchAlertIssuesResuls([]);
        } else {
          if (result.length == 0) {
            toast("No alerts found ! ");
          }
          setSearchAlertIssuesResuls(result);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("search alerts fail...", err);
        // for developing purpose
        toast(err.message + ", please try again");
        setSearchAlertIssuesResuls([]);
        setIsLoading(false);
        // toast("Search failed, please try later");
      });
  };
  const renderTypeOptions = () => {
    const opts = [{ label: "Select Type", value: "" }];
    ALERTS_TYPES.map((item: any, idx: number) => {
      opts.push({
        label: item,
        value: item,
      });
    });

    return opts;
    // return ALERTS_TYPES.map((item: string) => (
    //   <option value={item} key={item}>
    //     {item}
    //   </option>
    // ));
  };

  const handleDateTimeChange = (
    dateTime: Moment | null,
    dateTimeString: string,
    fieldName: string
  ) => {
    setFilter({
      ...filter,
      [fieldName]: dateTimeString,
    });
  };

  const getCustodyListPromise = (refID: string) => {
    console.log("getCustodyListPromise refID, get custodies item=>", refID);
    const url = `/alerts/${BIZ_CODE}?get_chains=${refID}`;
    return new Promise((resolve, reject) => {
      VALUE_CHAIN_API.get(url)
        .then((res: any) => {
          console.log("get custody list id list init Alerts res==>", res);

          if (res.status == "200") {
            const result = res.data.body;
            // console.log("get the ref id list init search ==>>", result);
            // setRefIdList(result);
            //   setDataList(result)
            return resolve(result);
            // setCustodyList(result);
          } else {
            console.log("error not 200 code");
            return reject("error not 200 code");
          }
          //   setFilter({
          //     ...filter,
          //     [selectorName]: selectorVal,
          //   });
        })
        .catch((err) => {
          console.log("get chain err", err);
          return reject(err);
          //   setFilter({
          //     ...filter,
          //     [selectorName]: selectorVal,
          //   });
        });
    });
  };

  const getAllAlertTypePromise = () => {
    const promList: any = [];

    ALERTS_TYPES.map((item) => {
      const alertProm = getOneAlertTypePromise(item);
      promList.push(alertProm);
    });

    return new Promise((resolve, reject) => {
      Promise.all(promList)
        .then((allPromiseRes) => {
          console.log("getAllAlertTypePromise allPromiseRes", allPromiseRes);

          const result: any = {};
          ALERTS_TYPES.map((item, index) => {
            result[item] = allPromiseRes[index];
          });

          return resolve(result);
        })
        .catch((err) => {
          console.log("get id all promise err", err);
          return reject(err);
        });
    });
  };
  const getOneAlertTypePromise = (alertType: string) => {
    console.log("getAlertTypePromise refID, get custodies item=>", alertType);
    const url = `alerts/${BIZ_CODE}?type=${alertType}`;
    return new Promise((resolve, reject) => {
      VALUE_CHAIN_API.get(url)
        .then((res: any) => {
          console.log("get alert type list " + alertType, res);
          const result = res.data.body;
          // const idL = result.map((item: any) => item.barcode);
          // setIdList(idL);
          return resolve(result);
        })
        .catch((err) => {
          console.log("get alert type list", err);
          return reject(err);
        });
    });
  };

  const onSelectorChange = (e: any, selectorName: string) => {
    // const selectorName = e.target.name;
    const selectorVal = e;
    // const selectorVal = e.target.value;
    console.log("selected item=>", selectorName, selectorVal);
    // console.log("tracking level=>", trackingLevel);
    // const level = trackingLevel.filter((item) => item.level == selectedItem)[0];
    // setSelectedLevel(level);
    if (selectorName == "refID") {
      console.log("onSelectorChange refID, get custodies item=>", selectorVal);
      // reset the table data when ref id changes
      setSearchAlertIssuesResuls([]);
      if (selectorVal == "") {
        console.log("empty refIDDDd");
        setCustodyList([]);
        setFilter({
          ...filter,
          [selectorName]: selectorVal,
          chain: "",
        });
      } else {
        setIsLoading(true);
        const url = `/alerts/${BIZ_CODE}?get_chains=${selectorVal}`;
        VALUE_CHAIN_API.get(url)
          .then((res: any) => {
            console.log("get custody list id list init Alerts res==>", res);

            if (res.status == "200") {
              const result = res.data.body;
              console.log("get the ref id list init search ==>>", result);
              setCustodyList(result);
            } else {
              console.log("error not 200 code");
            }
            setFilter({
              ...filter,
              [selectorName]: selectorVal,
              chain: "",
            });
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("get chain err", err);
            setFilter({
              ...filter,
              [selectorName]: selectorVal,
              chain: "",
            });
            setIsLoading(false);
          });
      }

      //   const promList = []
      //   const custodyListPromise = getCustodyListPromise(selectorVal)
      //   promList.push(custodyListPromise)

      //   const productsIdsPromise = getIDsPromise( 'products')
      //   promList.push(productsIdsPromise)

      //   const batchIdsPromise = getIDsPromise( 'batches')
      //   promList.push(batchIdsPromise)
    } else {
      setFilter({
        ...filter,
        [selectorName]: selectorVal,
      });
    }

    // after all promise need to set the filter no matter all passed or fail
  };

  const renderRefIdOptions = () => {
    return (
      refIdList && getSelectorOptions(refIdList, "Select Custody", "refID")
    );
    // return (
    //   refIdList &&
    //   refIdList.map((item: any, index: number) => (
    //     <option value={item.refID} key={item.refID}>
    //       {item.refID}
    //     </option>
    //   ))
    // );
  };
  const renderCustodyOptions = () => {
    return custodyList && getSelectorOptions(custodyList, "Select Custody");

    // return (
    //   custodyList &&
    //   custodyList.map((item: any, index: number) => (
    //     <option value={item} key={index}>
    //       {item}
    //     </option>
    //   ))
    // );
  };
  const renderProdOrBatchOptions = () => {
    const opts = [{ label: "Select Product or Batch", value: "" }];
    prodOrBatchIdList &&
      prodOrBatchIdList.map((item: any, idx: number) => {
        opts.push({
          label: item,
          value: item,
        });
      });

    return opts;
    // return (
    //   prodOrBatchIdList &&
    //   prodOrBatchIdList.map((item: any, index: number) => (
    //     <option value={item} key={index}>
    //       {item}
    //     </option>
    //   ))
    // );
  };



  // new & static data
  const statsHeaderConfig = [
    {
      bgOne: "#FF4136",
      bgTwo: "#FF986A",
      icon: failureAlert,
      title: "Data Failure",
      value: dataFailureVal,
    },
    {
      bgOne: "#DF3F35",
      bgTwo: "#ED8051",
      icon: diversion,
      title: "Diversion Alert",
      value: diversionalertVal,
    },
    {
      bgOne: "#587DB5",
      bgTwo: "#4B938D",
      icon: counter,
      title: "IoT Comms Failure",
      value: iocVal,
    },
    {
      bgOne: "#36B9D0",
      bgTwo: "#4AE8A8",
      icon: boundary,
      title: "Boundary Alert",
      value: boundaryalertVal,
    },
    {
      bgOne: "#EC9437",
      bgTwo: "#F9D048",
      icon: temp,
      title: "Temperature Alert",
      value: tempAlertVal,
    },
    {
      bgOne: "#9B6021",
      bgTwo: "#807759",
      icon: valueAlert,
      title: "Multi-Location Alert",
      value: multilocVal,
    },
  ];

  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Alert ID',
      onChange: () => null,
      onSearch: () => null,
      allowClear: true,
      inputStyle: { width: '300px' }
    },
    {
      type: 'daterange',
      placeholder: 'Select Date Range',
      onChange: () => null
    },
  ];


  const statsData = (getBizCode() === "acg" || getBizCode() === "acc") ? [
    {
      text: 'Data Failure',
      number: 10,
    },
    {
      text: 'Diversion Alert',
      number: 0,
    },
    {
      text: 'IoT Comms Failure',
      number: 0,
    },
    {
      text: 'Boundary Alert',
      number: 0,
    },
    {
      text: 'Temperature Alert',
      number: 0,
    },
    {
      text: 'Shipment Delay',
      number: 0,
    }
  ] : [
    {
      text: 'Data Failure',
      number: dataFailureVal,
    },
    {
      text: 'Diversion Alert',
      number: diversionalertVal,
    },
    {
      text: 'IoT Comms Failure',
      number: iocVal,
    },
    {
      text: 'Boundary Alert',
      number: boundaryalertVal,
    },
    {
      text: 'Temperature Alert',
      number: tempAlertVal,
    },
    {
      text: 'Shipment Delay',
      number: multilocVal,
    }
  ]


  const staticTableData = (getBizCode() === "acg" || getBizCode() === "acc") ? [
    {
      "alert_ID": "ART001",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0026",
      "record_time": "06/03/24 18:47",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART002",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0052",
      "record_time": "06/03/24 18:52",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART003",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0078",
      "record_time": "06/03/24 18:54",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART004",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0104",
      "record_time": "06/03/24 18:54",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART005",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0130",
      "record_time": "06/03/24 18:56",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART006",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0156",
      "record_time": "06/03/24 18:58",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART007",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0182",
      "record_time": "06/03/24 19:00",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART008",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0208",
      "record_time": "06/03/24 19:02",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART009",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0234",
      "record_time": "06/03/24 19:04",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
    {
      "alert_ID": "ART010",
      "alert_type": "Temperature Data missing",
      "epc_ID": "EVE0260",
      "record_time": "06/03/24 19:06",
      "business_step": "Packing",
      "read_point": "Production Area"
    },
  ] : [
    {
      "alert_ID": "ALT001",
      "alert_type": "Data Failure",
      "epc_ID": "293179670003593000",
      "record_time": "02/08/2023 14:00",
      "business_step": "FishCatchOrProduction",
      "read_point": "627 Rowella Road, Rowella, Tasmania, 7277"
    },
    // {
    //   "alert_ID": "ALT002",
    //   "alert_type": "Diversion Alert",
    //   "epc_ID": "23214 (Lot Number)",
    //   "record_time": "03/08/2023 13:00",
    //   "business_step": "Shipping",
    //   "read_point": "30/40 Wellington St, Launceston Tasmania 7250"
    // },
    // {
    //   "alert_ID": "ALT003",
    //   "alert_type": "Temperature Alert",
    //   "epc_ID": "9853244 (Pack Number)",
    //   "record_time": "03/08/2023 15:00",
    //   "business_step": "Shipping",
    //   "read_point": "78 Emu Bay Rd, Deloraine Tasmania 7304"
    // }
  ];

  const alertColumns = [
    {
      title: formatTitle('Alert ID'),
      dataIndex: "alert_ID",
      ...TABLE_CELL_CONFIG,
    },
    {
      title: formatTitle('Alert Type'),
      dataIndex: "alert_type",
      ...TABLE_CELL_CONFIG,
    },
    {
      title: formatTitle(`${(getBizCode() === "acg" || getBizCode() === "acc") ? 'EVENT ID' : 'EPC ID'}`),
      dataIndex: "epc_ID",
      ...TABLE_CELL_CONFIG,
    },
    {
      title: formatTitle('Record Time'),
      dataIndex: "record_time",
      ...TABLE_CELL_CONFIG,
    },
    {
      title: formatTitle('Business Step'),
      dataIndex: "business_step",
      ...TABLE_CELL_CONFIG,
    },
    {
      title: formatTitle('Read Point'),
      dataIndex: "read_point",
      ...TABLE_CELL_CONFIG,
    },
  ]

  return (
    <>
      {/* <StatsHeader statsConfig={statsHeaderConfig} /> */}
      <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData} />
      </div>
      {/* <FilterHeader
        inputsConfig={filterInputConfig}
      /> */}
      <TableCard>
        <AdiTable
          fullWidth
          marginTop="20px"
          tableData={staticTableData}
          columns={alertColumns}
          extraPaginationPadding
        />
      </TableCard>
    </>
  )

  // return (
  //   <>
  //     <Loading show={isLoading} />
  //     <ChainPageContainer style={{ marginLeft: "0px" }}>
  //       <Toaster />
  //       <GridFive style={{ gridGap: "0" }}>
  //         {alertCards &&
  //           alertCards.map((rec, index) => (
  //             <Statistics
  //               bgOne={rec.bgOne}
  //               bgTwo={rec.bgTwo}
  //               icons={rec.icons}
  //               title={rec.title}
  //               value={rec.value}
  //               key={index}
  //             />
  //           ))}
  //         {/* {CardsData.map((rec) => (
  //         <Statistics
  //           bgOne={rec.bgOne}
  //           bgTwo={rec.bgTwo}
  //           icons={rec.icons}
  //           title={rec.title}
  //           value={rec.value}
  //         />
  //       ))} */}
  //       </GridFive>
  //       <div style={{ marginBottom: "0px", marginTop: "5px" }}>
  //         <GridSeven>
  //           <div>
  //             <Input
  //               type="select"
  //               options={renderRefIdOptions()}
  //               placeholder="SELECT REF ID"
  //               onChange={(value: any) => onSelectorChange(value, "refID")}
  //               value={filter.refID}
  //             />
  //             {/* <StyledSelect
  //               onChange={onSelectorChange}
  //               required
  //               name="refID"
  //               value={filter.refID}
  //             >
  //               <option value="" selected>
  //                 SELECT REF ID
  //               </option>
  //               {renderRefIdOptions()}
  //             </StyledSelect> */}
  //           </div>
  //           <div>
  //             <Input
  //               type="select"
  //               options={renderCustodyOptions()}
  //               placeholder="SELECT CUSTODY"
  //               onChange={(value: any) => onSelectorChange(value, "chain")}
  //               value={filter.chain}
  //             />
  //             {/* <StyledSelect
  //               onChange={onSelectorChange}
  //               required
  //               name="chain"
  //               value={filter.chain}
  //             >
  //               <option value="" selected>
  //                 SELECT CUSTODY
  //               </option>
  //               {renderCustodyOptions()}
  //             </StyledSelect> */}
  //           </div>
  //           <div>
  //             <Input
  //               type="select"
  //               options={renderTypeOptions()}
  //               placeholder="SELECT TYPE"
  //               onChange={(value: any) => onSelectorChange(value, "type")}
  //               value={filter.type}
  //             />
  //             {/* <StyledSelect
  //               onChange={onSelectorChange}
  //               required
  //               id="trackIdSelector"
  //               name="type"
  //               value={filter.type}
  //             >
  //               <option value="" selected>
  //                 SELECT TYPE
  //               </option>
  //               {renderTypeOptions()}
  //             </StyledSelect> */}
  //           </div>

  //           {/* <StyledSelect
  //           onChange={onSelectorChange}
  //           required
  //           id="typeAlert"
  //           name="product_or_batch"
  //         >
  //           <option value="" selected>
  //             SELECT PRODUCT OR BATCH
  //           </option>

  //         </StyledSelect> */}
  //           <Input
  //             type="datalist"
  //             options={renderProdOrBatchOptions()}
  //             value={filter.product_or_batch}
  //             onChange={(e: any) => onSelectorChange(e, "product_or_batch")}
  //             placeholder="Product or batch code"
  //           />

  //           {/* <StyledTextInput
  //             name="product_or_batch"
  //             onChange={(e: any) => onSelectorChange(e, "product_or_batch")}
  //             placeholder="TYPE PRODUCT OR BATCH CODE"
  //             type="text"
  //             list="prodOrBatchList"
  //             value={filter.product_or_batch}
  //           />

  //           <datalist id="prodOrBatchList">
  //             {renderProdOrBatchOptions()}
  //           </datalist> */}

  //           <Input
  //             type="date"
  //             placeholder="From Date"
  //             onChange={(date: any, dateString: string) =>
  //               handleDateTimeChange(date, dateString, "start_date")
  //             }
  //           />
  //           <Input
  //             type="date"
  //             placeholder="To Date"
  //             onChange={(date: any, dateString: string) =>
  //               handleDateTimeChange(date, dateString, "end_date")
  //             }
  //           />
  //           {/* <DateTimeContainer style={{ width: "98%" }}>
  //             <Space direction="vertical" style={{ width: "100%" }}>
  //               <DatePicker
  //                 format={DATE_FORMAT_LIST}
  //                 placeholder="From Date"
  //                 // value={filter.start_date}
  //                 onChange={(date, dateString) =>
  //                   handleDateTimeChange(date, dateString, "start_date")
  //                 }
  //               />
  //             </Space>
  //           </DateTimeContainer> */}
  //           {/* <DateTimeContainer style={{ width: "98%" }}>
  //             <Space direction="vertical" style={{ width: "100%" }}>
  //               <DatePicker
  //                 format={DATE_FORMAT_LIST}
  //                 placeholder="To Date"
  //                 // value={filter.end_date}
  //                 onChange={(date, dateString) =>
  //                   handleDateTimeChange(date, dateString, "end_date")
  //                 }
  //               />
  //             </Space>
  //           </DateTimeContainer> */}

  //           <SearchButton onSearchClick={handleSearch} />
  //         </GridSeven>
  //       </div>
  //       {searchAlertIssuesResuls && searchAlertIssuesResuls.length > 0 ? (
  //         <AdiTable tableData={searchAlertIssuesResuls} columns={columns} />
  //       ) : null}
  //       {/* {searchAlertIssuesResuls && searchAlertIssuesResuls.length > 0 ? (
  //         <SimpleTableAlerts trackData={searchAlertIssuesResuls} />
  //       ) : null} */}
  //     </ChainPageContainer>
  //   </>
  // );
};

export default Alerts;

